/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


html, 
body {
    background: #10172f;
    color: #fff;
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
}
.categorySelectionButton {
    @apply border;
    @apply border-gray-300;
    @apply p-6;
    @apply rounded-lg;
}

.darkblueBackground {
    background-color: #10172f;
}


.categorySelectionButton:hover {
    box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.8);
    cursor: pointer;
}