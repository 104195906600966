.chat-room-container {
   
    display: flex;
    flex-direction: column;
    margin: 16px;
    background-color:rgb(79, 149, 196);
    max-height: 420px;
    max-width: 300px;
    padding: 10px;
    border-radius: 1vh;
    position: absolute;
    right: 0px;
    width: 300px;
    bottom: 60px;
  }
  
  .chat-room-top-bar {
    display: flex;
    align-items: center;
    
  }
  
  .chat-room-top-bar h1 {
    flex: 1;
  }
  
  .messages-container {
    flex: 1;
    min-height: 100px;
    overflow: auto;
    border-radius: 7px 7px 0 0;
    margin-bottom: 8px;
    background-color: rgb(244, 248, 250);
  }
  
  .messages-list {
    list-style-type: none;
    padding: 1;
    
  }