.message-item {
    display: flex;
    margin: 8px auto;
  }
  
  .my-message {
    justify-content: flex-end;
  }
  
  .received-message {
    justify-content: flex-start;
  }
  
  .message-body-container {
    max-width: 100%;
  }
  
  .message-body {
    flex: 1;
    border-radius: 4px;
    padding: 8px 12px;
    word-break: break-word;
  }
  
  .message-user-name {
    font-size: 1rem;
    padding-left: 12px;
    color: rgb(231, 83, 15);
  }
  
  .my-message .message-body {
    background-color: rgb(243, 204, 204);
    color: rgb(31, 28, 28);
  }
  
  .received-message .message-body {
    background-color: rgb(177, 190, 223);
    color: rgb(5, 5, 5);
  }
  
  .message-avatar-container {
    align-self: flex-end;
    margin: 0px 8px;
  }
  
  .message-avatar {
    vertical-align: middle;
    border-radius: 50%;
  background-color: tomato;
    height: 28px;
    width: 28px;
  }