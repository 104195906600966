.new-message-form {
    display: flex;
  }
  
  .emoBtn{
    display: flex;   

  }
  .plane{
    transform:rotate(90deg);
  }

  .btn{
    font-size: 1rem;
    font-weight: 600;
 
  }
  .new-message-input-field {
    flex: 1;
    font-size: 1rem;
    padding: 8px 12px;
    resize: none;
    border-radius: 8px;
    color: black;
  }
  
  .send-message-button {
    font-size: 1rem;
    font-weight: 600;
    color: white;
    background: rgb(4, 49, 92);
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    margin-left: 8px;
   
  }.send-message-button:hover {
    background-color: tomato;
  }
  
  .new-message-input-field,
  .send-message-button {
    border-color: #9a9a9a;
  }